import api from "@/base/utils/request";

//获取
export const getCycCircleSendConfig = data => {
    return api({
        url: "/admin/cyc_circle/settings/getCycCircleSendConfig",
        method: "post",
        data
    });
};
//保存
export const setCycCircleSendConfig = data => {
    return api({
        url: "/admin/cyc_circle/settings/setCycCircleSendConfig",
        method: "post",
        data
    });
};
