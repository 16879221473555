<template>
  <div class="list-content" v-loading="loading">
    <p class="save-title" style="margin-top: 4px">发布资料</p>

    <el-form class="save-content" label-width="82px">
      <el-form-item label="头像：">
        <div class="avatar">
          <single-media-wall
            v-model="config.avatar"
            addText=""
            :width="85"
            :height="85"
            borderRadius="50%"
            useDefaultAvatar
          ></single-media-wall>
        </div>
      </el-form-item>
      <el-form-item label="昵称：">
        <el-input v-model="config.nickname"></el-input>
      </el-form-item>
    </el-form>

    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import {
  getCycCircleSendConfig,
  setCycCircleSendConfig,
} from "../api/circle-send-config";
import FixedActionBar from "@/base/layout/FixedActionBar";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";

export default {
  components: { FixedActionBar, SingleMediaWall },
  data() {
    return {
      loading: false,
      config: [],
    };
  },
  watch: {},
  created() {
    this.getDatail();
  },
  methods: {
    getDatail() {
      this.loading = true;
      getCycCircleSendConfig()
        .then((res) => {
          this.config = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    saveConfig() {
      this.loading = true;
      setCycCircleSendConfig({ config: this.config })
        .then((res) => {
          this.$message.success(res.msg);
          this.getDatail();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.save-title {
  padding: 0 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-left: 3px #3479ef solid;
  line-height: 16px;
  margin-bottom: 19px;
}

.save-content {
  padding: 12px 4px 10px;

  .el-form-item__label,
  ::v-deep.el-form-item__label {
    white-space: nowrap;
  }
  .el-input {
    max-width: 295px;
  }
}
</style>
